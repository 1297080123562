@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  height: 100%;
}

#root {
  /* display: flex; */
  /* flex: 1 */
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
